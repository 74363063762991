import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import styled from "@emotion/styled"
import Button from "../buttons/button"

const Outer = styled.div`
  max-width: var(--site-width);
  margin: 0 auto;
`

const ThreeColumnGrid = styled.div`
  display: grid;
  row-gap: 64px;
  padding: ${props => props.padding || "0"};

  h2 {
    text-align: center;
  }

  .ImgContainer {
    width: 85px;
    height: 85px;
    margin: 0 auto 32px auto;
  }

  .centerThisLink {
    text-align: center;
    margin-top: 32px;
  }

  // partners section start
  .Partners_Top_Padding {
    padding-top: 62px;
  }
  .ImgContainers_Partner {
    width: 170px;
    height: 170px;
    margin: 0 auto 32px auto;
  }
  .Partners_TextTitle {
    text-align: center;
  }
  // partners section end

  @media (min-width: 1200px) {
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: center;
    column-gap: 32px;
  }
`

const HomeServices = () => (
  <>
    <Outer>
      <ThreeColumnGrid>
        <div className="Grid_One">
          <div className="ImgContainer">
            <StaticImage
              src="../../images/home/installation_icon.png"
              width={85}
              height={85}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="fire and safety installation services"
            />
          </div>
          <h2>Installations</h2>
          <p>
            Properly installed fire alarms and sprinkler systems are fundamental
            to fire protection. Click here to learn more about our guarantee
            that you can depend on our first-rate installations work.
          </p>
          <ButtonComponent cta="Book An Installation" link="/" />
        </div>
        <div className="Grid_Two">
          <div className="ImgContainer">
            <StaticImage
              src="../../images/home/inspections_icon.png"
              width={85}
              height={85}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="fire and safety inspections services"
              style={{ marginBottom: `1.45rem` }}
            />
          </div>
          <h2>Inspections</h2>
          <p>
            Do you need a second opinion on your existing installation? Would
            you like to be certain that your fire safety equipment has been
            properly maintained and kept up to code? Ask us about our multistep
            inspection process that certifies your protection.
          </p>
          <ButtonComponent cta="Book An Inspection" link="/" />
        </div>
        <div className="Grid_Three">
          <div className="ImgContainer">
            <StaticImage
              src="../../images/home/training_icon.png"
              width={85}
              height={85}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="fire safety training services"
              style={{ marginBottom: `1.45rem` }}
            />
          </div>
          <h2>Training</h2>
          <p>
            Do you need to learn what fire extinguisher works for what and how
            to use it? The folks here at Relentless Fire and Safety will happily
            teach you everything you know to correctly manage your fire safety
            program.
          </p>
          <ButtonComponent cta="Book Training" link="/" />
        </div>
      </ThreeColumnGrid>
    </Outer>
  </>
)

export default HomeServices

function ButtonComponent({ cta, link }) {
  return (
    <div className="centerThisLink">
      <Link to={link}>
        <Button cta={cta} title={cta} />
      </Link>
    </div>
  )
}
