import React from "react"
import styled from "styled-components"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import BookingHeroWithProps from "../../components/heros/bookingHeroWithProps"
import InspectionContactForm from "../../components/forms/inspectionContactForm"

const FireInspectionsBookingPage = () => {
  return (
    <Layout>
      <Seo
        title={"Book A Fire Safety Inspection Appointment"}
        description={
          "Schedule a fire inspection today. Ensure the safety of your building and occupants."
        }
      />

      <BookingHeroWithProps titleText="Book A Fire Inspection" />

      <SiteWidthWrapper>
        <SubHeading>
          Expert Fire Inspection Services at Your Fingertips
        </SubHeading>
        <p>
          Ensure the safety of your building and occupants by scheduling a
          professional fire inspection today. Our expert fire inspection
          services will help you identify potential fire hazards and take
          necessary precautions to prevent them. Book now to secure your peace
          of mind and keep your property safe.
        </p>

        <InspectionContactForm />
      </SiteWidthWrapper>
    </Layout>
  )
}

const SiteWidthWrapper = styled.div`
  padding: ${props => props.padding || "64px 0"};
  background: ${props => props.background};
  margin: 0 auto;
  max-width: var(--site-width);
`

const SubHeading = styled.h2`
  text-align: center;
`

export default FireInspectionsBookingPage
