import * as React from "react"
import axios from "axios"
import { useForm } from "react-hook-form"
import { navigate } from "gatsby"

const InspectionContactForm = () => {
  const [isDisabled, setIsDisabled] = React.useState(false)
  const [success, setSuccess] = React.useState(null)
  const [formInteraction, setFormInteraction] = React.useState(false)

  const handleFocus = () => {
    !formInteraction && setFormInteraction(true)
  }

  /* This is a useEffect hook that is triggered when the formInteraction state is set to true. It adds
  the recaptcha script to the head of the document and then removes it when the component unmounts. */
  React.useEffect(() => {
    if (formInteraction) {
      const recaptchaScript = document.createElement("script")
      recaptchaScript.src = `https://www.google.com/recaptcha/api.js?render=6Le16y8lAAAAANjuwUV3VWTAsE6t8e7RbG7r_PWj`
      recaptchaScript.async = true
      recaptchaScript.defer = true
      document.head.appendChild(recaptchaScript)
      return () => {
        // Get all script tags: returns HTMLcollection
        const scripts = document.getElementsByTagName("script")
        // Loop through the HTMLcollection (array-like but not array)
        for (var i = 0; i < scripts.length; i++) {
          // find script whose src value includes "recaptcha/releases"
          // this script is added when main recaptcha script is loaded

          if (
            scripts.item(i).attributes.getNamedItem("src") &&
            scripts
              .item(i)
              .attributes.getNamedItem("src")
              .value.includes("recaptcha/releases")
          ) {
            document.head.removeChild(scripts.item(i)) // remove script from head
          }
        }
        document.head.removeChild(recaptchaScript) // remove main recaptcha script from head
        // remove the recaptcha badge from the bottom right corner
        let badge = document.querySelector(".grecaptcha-badge")
        if (badge) {
          badge.parentElement.remove()
        }
      }
    }
  }, [formInteraction])
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm()

  const onSubmit = async data => {
    try {
      await axios({
        url: "/api/inspectionContactCreation",
        method: "POST",
        data,
      }).then(res => {
        if (res.status === 200) {
          reset()
          setSuccess(true)
          setTimeout(() => {
            setSuccess(null)
            setIsDisabled(false)
          }, 3000)
        } else {
          console.log("res.status not 200")
          setSuccess(false)
          reset()
        }
      })
    } catch (err) {
      if (err.response) {
        console.log("server responded with non 2xx code: ", err.response.data)
      } else if (err.request) {
        console.log("no response received: ", err.request)
      } else {
        console.log("Error setting up response: ", err.message)
      }
    }
  }

  const addContact = async formData => {
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute("6Le16y8lAAAAANjuwUV3VWTAsE6t8e7RbG7r_PWj", {
          action: "submit",
        })
        .then(token => {
          formData.token = token
          onSubmit(formData, token)
        })
    })
  }

  return (
    <div className="formWrapper">
      {success === null && (
        <form onSubmit={handleSubmit(addContact)}>
          <label htmlFor="firstName">First Name:</label>
          <input
            id="firstName"
            onFocus={handleFocus}
            {...register("firstName", { required: "First Name is required" })}
          />
          {errors.firstName && <p>{errors.firstName.message}</p>}

          <label htmlFor="lastName">Last Name:</label>
          <input
            id="lastName"
            onFocus={handleFocus}
            {...register("lastName", { required: "Last Name is required" })}
          />
          {errors.lastName && <p>{errors.lastName.message}</p>}

          <label htmlFor="email">Email:</label>
          <input
            id="email"
            onFocus={handleFocus}
            type="email"
            {...register("email", { required: "Email is required" })}
          />
          {errors.email && <p>{errors.email.message}</p>}

          <label htmlFor="phone">Phone:</label>
          <input
            id="phone"
            onFocus={handleFocus}
            type="tel"
            {...register("phone", { required: "Phone is required" })}
          />
          {errors.phone && <p>{errors.phone.message}</p>}

          <label htmlFor="city">City:</label>
          <input
            id="city"
            onFocus={handleFocus}
            {...register("city", { required: "City is required" })}
          />
          {errors.city && <p>{errors.city.message}</p>}

          <button type="submit" disabled={isDisabled}>
            Submit
          </button>
        </form>
      )}
      {success === true && (
        <div className="">
          <p className="">Form Success! Style and replace this message</p>
        </div>
      )}
    </div>
  )
}

export default InspectionContactForm
