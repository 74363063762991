import React from "react"
import Layout from "../../components/layout"

export default function ThankYouPage() {
  return (
    <Layout>
      <h1>Thank You!</h1>
      <p>Your form has been submitted successfully.</p>
    </Layout>
  )
}
