import React, { useState } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import BookingHero from "../../components/heros/bookingHero"
import Button from "../../components/buttons/button"
import { Script } from "gatsby"

const BookingsHome = () => (
  <Layout>
    <Seo
      title={"Book Fire Inspection, Safety Training & Prevention Equipment"}
      description={
        "Schedule a fire inspection or training today to ensure the safety of your building and occupants. Get fire prevention and monitoring equipment for added protection. Book now!"
      }
    />
    <BookingHero />

    <SiteWidthWrapper>
      <SubHeading>
        Expert Fire Protection Services at Your Fingertips
      </SubHeading>
      <p>
        Get started on protecting your building and occupants from fire hazards
        today. Schedule a fire inspection or training to ensure your property is
        safe. Don't forget to also get fire prevention and monitoring equipment
        for added protection. Book now to secure your peace of mind!
      </p>
      <ThreeColumnGrid padding={"32px 0"}>
        <div className={"centerThisButton"}>
          <ButtonComponent
            cta={"Book an Inspection"}
            link="/bookings/fire-inspection/"
            title={"book a fire inspection"}
          />
        </div>
        <div className={"centerThisButton"}>
          <ButtonComponent
            title={"book a fire equipment installation"}
            cta={"Book an Installation"}
            link=""
          />
        </div>
        <div className={"centerThisButton"}>
          <ButtonComponent
            title={"book fire safety training"}
            cta={"Book a Training"}
            link=""
          />
        </div>
      </ThreeColumnGrid>
    </SiteWidthWrapper>
  </Layout>
)

export default BookingsHome

const SiteWidthWrapper = styled.div`
  padding: ${props => props.padding || "64px 0"};
  background: ${props => props.background};
  margin: 0 auto;
  max-width: var(--site-width);

  .iframeButtonOne {
    display: none;
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 0px;
  }
`

const ThreeColumnGrid = styled.div`
  display: grid;
  row-gap: 1.2rem;
  padding: ${props => props.padding || "0"};

  justify-content: center;
  align-items: center;

  .centerThisButton {
    text-align: center;
  }
  @media (min-width: 900px) {
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: center;
    column-gap: 1rem;
  }
`

const SubHeading = styled.h2`
  text-align: center;
`

function ButtonComponent({ cta, link }) {
  return (
    <div className="centerThisLink">
      <Link to={link}>
        <Button cta={cta} title={cta} />
      </Link>
    </div>
  )
}
